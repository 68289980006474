











































































































































































import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { NS_ALERTS, NS_POI, NS_STATIONS } from '@/constants/app.constants';
import { PointOfInterest } from '@/models/position.model';
import { SimulationService } from '@/services/simulation.service';
import { Simulation, SimulationRunConfiguration } from '@/models/simulation.model';
import { Station } from '@/models/station.model';
import ConfirmationDialog from '@/components/shared/ConfirmationDialog.component.vue';
import IntraVersionSelect from '@/components/shared/IntraVersionSelect.component.vue';
import LoadingBar from '@/components/shared/LoadingBar.component.vue';
import POISelect from '@/components/shared/POISelect.component.vue';
import PointOfInterestAddDialog from '@/components/points-of-interest/PointOfInterestAddDialog.component.vue';
import RealTimeMap from '@/components/real-time/RealTimeMap.component.vue';

@Component({
  name: 'SimulationRunView',
  components: {
    ConfirmationDialog,
    IntraVersionSelect,
    LoadingBar,
    PointOfInterestAddDialog,
    POISelect,
    RealTimeMap,
  },
})
export default class SimulationRunView extends Vue {
  @Prop() public runId?: number;
  @Prop() public simulationId?: number;
  @Prop() public isEmbed?: boolean;
  @Getter('getAllStations', { namespace: NS_STATIONS }) stations?: Station[];
  @Getter('getAllPointsOfInterest', { namespace: NS_POI }) pointsOfInterest?: PointOfInterest[];
  @Action('fetchPointsOfInterest', { namespace: NS_POI }) public fetchPointsOfInterest: any;
  @Action('fetchStations', { namespace: NS_STATIONS }) public fetchStations: any;
  @Action('addAlert', { namespace: NS_ALERTS }) public addAlert: any;

  public isLoading: boolean = false;
  public shouldRemove: boolean = false;
  public addNew: boolean = false;
  public simulationStations: Station[] = [];
  public selectedTab: number = 0;
  public isNew: boolean = false;
  public isClone: boolean = false;
  public selectedRunId: number = -1;
  public selectedSimulationId: number = -1;
  public simulation: Simulation | null = null;
  public simulationRunConfiguration: Partial<SimulationRunConfiguration> = new SimulationRunConfiguration();

  public mounted() {
    this.selectedRunId = !_.isUndefined(this.runId) ? this.runId! : +this.$route.params.runId;
    this.selectedSimulationId = !_.isUndefined(this.simulationId) ? this.simulationId! : +this.$route.params.simulationId;
    this.isNew = this.$route.params.runId === 'new';
    this.isClone = this.$route.name === 'simulation-run-configuration-clone';

    this.loadData();
  }

  @Watch('runId')
  public onRunIdChange() {
    this.loadData();
  }

  public loadData() {
    this.isLoading = true;
    SimulationService.find(this.selectedSimulationId).then(
      (simulation) => {
        this.simulation = simulation;
        this.fetchPointsOfInterest();
        this.fetchStations();
        if (this.isNew) {
          SimulationService.getDefaultRun(this.selectedSimulationId).then((defaultRun) => {
            this.isLoading = false;
            this.simulationRunConfiguration.regionalConfig = defaultRun.regionalConfig;
            this.simulationRunConfiguration.stationConfigs = simulation.stationIds.map((stationId) => ({
              stationId,
              r2d2Config: _.get(_(defaultRun.stationConfigs).find({ stationId }), 'r2d2Config', ''),
              ackbarConfig: null
            }));
          });
        } else {
          SimulationService.getRun(this.selectedSimulationId, this.selectedRunId, this.isClone).then(
            (simulationRunConfiguration: SimulationRunConfiguration) => {
              this.isLoading = false;
              this.simulationRunConfiguration = simulationRunConfiguration;
            },
          );
        }
      },
      () => {
        this.isLoading = false;
      },
    );
  }

  public onConfirmRemove() {
    this.isLoading = true;
    SimulationService.removeRun(this.selectedSimulationId, this.selectedRunId).then(
      () => {
        this.isLoading = false;
        this.addAlert({
          type: 'success',
          header: 'Run was removed',
          message: 'You are getting redirected to simulation page',
          timeout: 5000,
        });
        this.$router.push({ name: 'simulation-details', params: { simulationId: `${this.selectedSimulationId}` } });
      },
      (error: ErrorEvent) => {
        this.isLoading = false;
        this.addAlert({
          type: 'error',
          header: 'There was a problem removing the Run',
          message: error.message,
          timeout: 5000,
        });
      },
    );
  }

  public getFilteredStations() {
    if (this.stations) {
      return this.stations.filter((station) => _.includes(this.simulation?.stationIds, station.stationId));
    }
    return [];
  }

  @Watch('stations')
  public onStationChange() {
    if (this.stations) {
      this.simulationStations = this.stations.filter((station) => _.includes(this.simulation?.stationIds, station.stationId));
    }
  }

  public onPOIAdded() {
    this.fetchPointsOfInterest();
  }

  public save() {
    if (this.simulation?.id) {
      const runConfiguration = _.cloneDeep(this.simulationRunConfiguration);
      if (!runConfiguration.serviceVersions?.intra) {
        _.set(runConfiguration, 'serviceVersions.intra', null);
      }
      if (!runConfiguration.serviceVersions?.eqa) {
        _.set(runConfiguration, 'serviceVersions.eqa', null);
      }

      SimulationService.saveRun(this.simulation.id, runConfiguration).then(() => {
        this.$router.push({ name: 'simulation-list' });
        this.addAlert({
          timeout: 5000,
          type: 'success',
          header: this.isClone ? 'Run Configuration Cloned Successfully!' : 'Run Configuration Saved!',
          message: 'Please wait until the simulation is ready',
        });
      });
    }
  }
}
