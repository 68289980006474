













































































import _ from 'lodash';
import moment from 'moment-timezone';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { SimulationService } from '@/services/simulation.service';
import { Simulation, SimulationRegional, SimulationRunConfiguration } from '@/models/simulation.model';
import { NS_ALERTS, NS_FILTERS_SIMULATION_ANALYSIS, NS_STATIONS } from '@/constants/app.constants';
import { Station } from '@/models/station.model';
import { getNameByChannelId } from '@/filters/channel.filter';
import { Message } from '@/models/message.model';
import { ChartConfiguration, PlotLineConfiguration } from '@/models/chart.model';
import { ChartUtils } from '@/utils/chart.utils';
import { FiltersSimulationAnalysis } from '@/models/states/filters-state.model';
import ChartsGroup from '@/components/charts/ChartsGroup.component.vue';
import LoadingBar from '@/components/shared/LoadingBar.component.vue';
import MessagesTable from '@/components/shared/MessagesTable.component.vue';
import OptionsSimulationAnalysis from '@/components/options-panels/OptionsSimulationAnalysis.component.vue';
import RealTimeMap from '@/components/real-time/RealTimeMap.component.vue';
import SimulationRunView from '@/views/simulations/SimulationRun.view.vue';
import SplitPanelComponentBase from '@/components/shared/SplitPanelComponentBase';

@Component({
  name: 'SimulationAnalysisView',
  components: {
    ChartsGroup,
    LoadingBar,
    MessagesTable,
    OptionsSimulationAnalysis,
    RealTimeMap,
    SimulationRunView,
  },
})
export default class SimulationAnalysisView extends SplitPanelComponentBase {
  @Getter('getAllStations', { namespace: NS_STATIONS }) stations?: Station[];
  @Action('fetchStations', { namespace: NS_STATIONS }) public fetchStations: any;
  @Getter('getFilters', { namespace: NS_FILTERS_SIMULATION_ANALYSIS }) public filters?: FiltersSimulationAnalysis;
  @Action('updateFilters', { namespace: NS_FILTERS_SIMULATION_ANALYSIS }) public updateFilters: any;
  @Action('addAlert', { namespace: NS_ALERTS }) public addAlert: any;

  public simulation: Simulation | null = null;
  public selectedRunConfiguration: SimulationRunConfiguration | null = null;
  public isLoading: boolean = true;
  public isLoadingReadings: boolean = true;
  public isLoadingMessages: boolean = true;
  public isLoadingRegional: boolean = true;
  public tab: number = 0;
  public totalMessages: number = 0;
  public plotLines: PlotLineConfiguration[] = [];
  public messages: Message[] = [];
  public mapMessages: Message[] = [];
  public regional: SimulationRegional | null = null;
  public channelReadings: ChartConfiguration[] = [];
  public extraReadings: ChartConfiguration[] = [];
  public observedTime: number = 0;
  public isFiltersOpen: boolean = true;

  public mounted() {
    SimulationService.find(this.$route.params.simulationId).then((simulation) => {
      this.simulation = simulation;
      this.observedTime = simulation.toTime;
      this.fetchStations();
    });
  }

  public onRunChange(run: SimulationRunConfiguration | null = null) {
    this.selectedRunConfiguration = run;
  }

  public getTimelineLabel() {
    return `Time: ${moment(this.observedTime).format('HH:mm:ss SSS')}`;
  }

  public loadReadings() {
    if (!this.simulation) {
      return;
    }

    this.saveScrollPosition();

    this.channelReadings = [];
    this.isLoadingReadings = true;
    SimulationService.getRunResultsReadings(this.simulation.id!, this.filters?.run!, {
      from: this.filters?.zoomStart ? this.filters?.zoomStart : this.simulation.fromTime,
      to: this.filters?.zoomEnd ? this.filters?.zoomEnd : this.simulation.toTime,
      stationIds: this.filters?.stationIds || this.simulation.stationIds,
      nodes: this.filters?.nodes?.length ? this.filters?.nodes : null,
      channels: this.filters?.channels?.length ? this.filters?.channels : null,
      readingFilterType: this.filters?.bpFilter ? 'BUTTERWORTH_BANDPASS' : null,
      includeSeries: true,
      downSample: true,
    }).then((results: any) => {
      this.isLoadingReadings = false;
      this.channelReadings = _(results.readings)
        .groupBy('channelId')
        .map((channel, channelId: string) => ({
          name: getNameByChannelId(parseInt(channelId, 10)),
          key: channelId,
          series: channel.map((node) => ({
            name: `Node ${node.nodeId} - ${node.stationId}`,
            key: `${node.nodeId}-${node.stationId}`,
            data: node.data,
          })),
        }))
        .value();
      this.restoreScrollPosition();

      this.loadMessages();
    });
  }

  public loadMessages() {
    if (!this.simulation) {
      return;
    }

    this.isLoadingMessages = true;
    SimulationService.getRunResultsMessages(this.simulation.id!, this.filters?.run!, {
      from: this.filters?.zoomStart ? this.filters?.zoomStart : this.simulation.fromTime,
      to: this.filters?.zoomEnd ? this.filters?.zoomEnd : this.simulation.toTime,
      stationIds: this.filters?.stationIds || this.simulation.stationIds,
      messageTypes: this.filters?.messageTypes && this.filters?.messageTypes.length ? this.filters.messageTypes : null,
    }).then((response: any) => {
      this.isLoadingMessages = false;
      this.messages = response.elements;
      this.mapMessages = _.union(this.mapMessages, this.messages);
      this.totalMessages = response.totalCount;
      this.plotLines = ChartUtils.convertMessagesToPlotLines(this.messages);
    });
  }

  public loadRegional() {
    if (!this.simulation) {
      return;
    }

    this.isLoadingRegional = true;
    SimulationService.getRunResultsRegional(this.simulation.id!, this.filters?.run!, {
      from: this.filters?.zoomStart ? this.filters?.zoomStart : this.simulation.fromTime,
      to: this.filters?.zoomEnd ? this.filters?.zoomEnd : this.simulation.toTime,
      observedTime: this.observedTime,
    }).then((response: SimulationRegional) => {
      this.isLoadingRegional = false;
      this.regional = response;
      this.mapMessages = [
        _.extend({}, response.lastSourceMessage, {
          pointsOfInterest: response.pointsOfInterest,
        }),
      ];
      this.extraReadings = this.getExtraReadings();
    });
  }

  public onObservedTimeChange() {
    this.loadRegional();
  }

  public getFilteredStations(): Station[] {
    if (this.stations) {
      return this.stations.filter((station) => _.includes(this.simulation?.stationIds, station.stationId));
    }
    return [];
  }

  public onZoomChange(event: { start: number; end: number }) {
    const start = moment(event.start);
    const end = moment(event.end).add(1, 'seconds');
    this.updateFilters({
      zoomStart: start.valueOf(),
      zoomEnd: end.valueOf(),
    });
  }

  @Watch('filters')
  public onFiltersChange() {
    this.loadReadings();
    this.loadRegional();
  }

  private getExtraReadings(): ChartConfiguration[] {
    if (!this.regional) {
      return [];
    }

    const EXTRA_READINGS = [
      { name: 'Stress Drop (MPa)', key: 'stressDropLine', rescaleFactor: 0.000001 },
      { name: 'Moment (Nm)', key: 'momentLine', rescaleFactor: 1 },
      { name: 'Magnitude (Mw)', key: 'magLine', rescaleFactor: 1 },
    ];

    const extraReadings: any[] = EXTRA_READINGS.map((extra) => {
      const data: number[][] = _.get(this.regional, extra.key, []);
      const rescaledData =
        extra.rescaleFactor && extra.rescaleFactor !== 1 ? data.map((datapoint) => [datapoint[0], datapoint[1] * extra.rescaleFactor]) : data;
      return {
        name: extra.name,
        key: extra.key,
        series: [
          {
            name: extra.name,
            key: extra.key,
            data: rescaledData,
          },
        ],
      };
    });

    return extraReadings.reverse();
  }
}
