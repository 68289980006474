




















import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { NS_STATIONS } from '@/constants/app.constants';
import { Customer } from '@/models/customer.model';

@Component({
  name: 'CustomerSelect',
})
export default class CustomerSelect extends Vue {
  @Getter('getCustomers', { namespace: NS_STATIONS }) customers?: Customer[];

  @Prop() public model!: string[];
  @Prop() public disabled?: boolean;
  @Prop({ default: true }) public multiple?: boolean;
  @Prop({ default: true }) public dense?: boolean;
  @Prop({ default: true }) public required?: boolean;
  @Prop({ default: false }) public clearable?: boolean;

  public innerModel: string[] = [];

  public mounted() {
    this.onModelChange();
  }

  @Watch('model')
  public onModelChange() {
    this.innerModel = _.clone(this.model);
  }
}
