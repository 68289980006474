import _ from 'lodash';
import moment from 'moment-timezone';
import axios from 'axios';
import { DATE_HOUR_FORMAT } from '@/constants/app.constants';
import {
  Simulation,
  SimulationQueryPrams,
  SimulationQueryResponse,
  SimulationRegional,
  SimulationRegionalRequest,
  SimulationRunConfiguration,
  SimulationServer,
} from '@/models/simulation.model';

export class SimulationService {
  public static query(params: SimulationQueryPrams): Promise<SimulationQueryResponse> {
    return axios.post<SimulationQueryResponse>(`/simulations/query`, params).then((response) => response.data);
  }

  public static find(simulationId: number | string): Promise<Simulation> {
    return axios
      .get<SimulationServer>(`/simulations/${simulationId}`)
      .then((response) => response.data)
      .then((simulation) => SimulationService.parseSimulation(simulation));
  }

  public static remove(simulationId: number | string): Promise<Simulation> {
    return axios.delete<Simulation>(`/simulations/${simulationId}`).then((response) => response.data);
  }

  public static save(simulation: Partial<SimulationServer>): Promise<Simulation> {
    return axios
      .post<SimulationServer>(`/simulations`, simulation)
      .then((response) => response.data)
      .then((newSimulation) => SimulationService.parseSimulation(newSimulation));
  }

  public static getRun(simulationId: number, runId: number, isClone: boolean = false): Promise<SimulationRunConfiguration> {
    return axios
      .get<SimulationRunConfiguration>(`/simulations/${simulationId}/runs/${runId}`)
      .then((response) => response.data)
      .then((simulationRunConfiguration: SimulationRunConfiguration) => {
        const runConfiguration = simulationRunConfiguration;

        if (!runConfiguration.serviceVersions) {
          runConfiguration.serviceVersions = { intra: null, eqa: null };
        }

        if (!runConfiguration.catalogEpicenterLocation) {
          runConfiguration.catalogEpicenterLocation = { lat: 0, long: 0 };
        }

        return isClone
          ? _.extend({}, _.omit(runConfiguration, ['id']), {
              name: `${runConfiguration.name} clone`,
            })
          : runConfiguration;
      });
  }

  public static getDefaultRun(simulationId: number): Promise<SimulationRunConfiguration> {
    return axios.get<SimulationRunConfiguration>(`/simulations/${simulationId}/runs/defaultValues`).then((response) => response.data);
  }

  public static saveRun(
    simulationId: number,
    simulationRunConfiguration: Partial<SimulationRunConfiguration>,
  ): Promise<SimulationRunConfiguration> {
    return axios
      .post<SimulationRunConfiguration>(`/simulations/${simulationId}/runs`, simulationRunConfiguration)
      .then((response) => response.data);
  }

  public static queryRun(simulationId: number): Promise<SimulationRunConfiguration[]> {
    return axios
      .get<SimulationRunConfiguration[]>(`/simulations/${simulationId}/runs`)
      .then((response) => _.get(response.data, 'runs', []));
  }

  public static removeRun(simulationId: number, runId: number): Promise<SimulationRunConfiguration[]> {
    return axios.delete<SimulationRunConfiguration[]>(`/simulations/${simulationId}/runs/${runId}`).then((response) => response.data);
  }

  public static getRunResultsReadings(simulationId: number, runId: number, filters: any): Promise<SimulationRunConfiguration[]> {
    return axios
      .post<SimulationRunConfiguration[]>(`/simulations/${simulationId}/runs/${runId}/results/readings/query`, filters)
      .then((response) => response.data);
  }

  public static downloadResults(simulationId: number, runId: number) {
    return axios.post(`/simulations/${simulationId}/runs/${runId}/results/files`, {}, { responseType: 'blob' }).then((response) => {
      const blob = new Blob([response.data], { type: 'application/zip' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `simulations/${simulationId}/runs/${runId}.zip`;
      link.click();
      URL.revokeObjectURL(link.href);
    });
  }

  public static getRunResultsMessages(simulationId: number, runId: number, filters: any): Promise<SimulationRunConfiguration[]> {
    return axios
      .post<SimulationRunConfiguration[]>(`/simulations/${simulationId}/runs/${runId}/results/messages/query`, filters)
      .then((response) => response.data);
  }

  public static getRunResultsRegional(
    simulationId: number,
    runId: number,
    filters: SimulationRegionalRequest,
  ): Promise<SimulationRegional> {
    return axios
      .post<SimulationRegional>(`/simulations/${simulationId}/runs/${runId}/results/regional/query`, filters)
      .then((response) => response.data);
  }

  public static parseSimulation(simulation: SimulationServer): Simulation {
    return _.extend({}, simulation, {
      date: moment
        .unix(simulation.fromTime / 1000)
        .toISOString()
        .substr(0, 10),
      time: moment.unix(simulation.fromTime / 1000).format(DATE_HOUR_FORMAT),
      displayInterval: (simulation.toTime - simulation.fromTime) / 1000,
    });
  }
}
