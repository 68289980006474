var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{attrs:{"items":_vm.runs,"item-value":"id","item-text":"name","label":"Runs","dense":"","prepend-icon":"mdi-rhombus-outline"},on:{"change":function($event){return _vm.$emit('update:model', _vm.innerModel)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":600},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":"","color":_vm._f("getColorFromRunStatus")(item.status)}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("getIconFromRunStatus")(item.status))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.statusDescription || 'No Info'))])]),_c('span',[_vm._v(_vm._s(item.name || 'No Name'))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":600},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":"","color":_vm._f("getColorFromRunStatus")(item.status)}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("getIconFromRunStatus")(item.status))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.statusDescription || 'No Info'))])]),_c('span',[_vm._v(_vm._s(item.name || 'No Name'))]),_c('v-spacer')]}}]),model:{value:(_vm.innerModel),callback:function ($$v) {_vm.innerModel=$$v},expression:"innerModel"}})}
var staticRenderFns = []

export { render, staticRenderFns }