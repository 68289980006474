









































































































import _ from 'lodash';
import moment from 'moment-timezone';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { DATE_DEFAULT_FORMAT, DATE_HOUR_FORMAT, NS_ALERTS, NS_FILTERS_SIMULATION, NS_STATIONS } from '@/constants/app.constants';
import { MESSAGE_TYPES } from '@/constants/message-types.constant';
import { Simulation, SimulationRunConfiguration, SimulationServer } from '@/models/simulation.model';
import { ChartConfiguration, PlotLineConfiguration } from '@/models/chart.model';
import { Message, MessagesQueryParams } from '@/models/message.model';
import { FiltersSimulation } from '@/models/states/filters-state.model';
import { ReadingsRequest, ReadingsResponse } from '@/models/response.model';
import { Station } from '@/models/station.model';
import { MessagesService } from '@/services/messages.service';
import { ReadingService } from '@/services/reading.service';
import { SimulationService } from '@/services/simulation.service';
import ChartsGroup from '@/components/charts/ChartsGroup.component.vue';
import ConfirmationDialog from '@/components/shared/ConfirmationDialog.component.vue';
import LoadingBar from '@/components/shared/LoadingBar.component.vue';
import MessagesTable from '@/components/shared/MessagesTable.component.vue';
import OptionsSimulation from '@/components/options-panels/OptionsSimulation.component.vue';
import RealTimeMap from '@/components/real-time/RealTimeMap.component.vue';
import RunsTable from '@/components/shared/RunsTable.component.vue';
import SplitPanelComponentBase from '@/components/shared/SplitPanelComponentBase';

@Component({
  name: 'SimulationDetailsView',
  components: {
    ChartsGroup,
    ConfirmationDialog,
    OptionsSimulation,
    LoadingBar,
    MessagesTable,
    RealTimeMap,
    RunsTable,
  },
})
export default class SimulationDetailsView extends SplitPanelComponentBase {
  @Getter('getAllStations', { namespace: NS_STATIONS }) stations?: Station[];
  @Action('fetchStations', { namespace: NS_STATIONS }) public fetchStations: any;
  @Getter('getFilters', { namespace: NS_FILTERS_SIMULATION }) public filters?: FiltersSimulation;
  @Action('addAlert', { namespace: NS_ALERTS }) public addAlert: any;

  public tab: number = 0;
  public isFiltersOpen: boolean = true;
  public simulation: Simulation = {
    stationIds: [],
    date: moment().subtract(5, 'minute').toISOString().substr(0, 10),
    time: moment().format(DATE_HOUR_FORMAT),
    fromTime: 0,
    toTime: 0,
    displayInterval: 300,
    name: '',
    description: '',
  };
  public readings: ChartConfiguration[] = [];
  public plotLines: PlotLineConfiguration[] = [];
  public runConfigurations: SimulationRunConfiguration[] = [];
  public totalMessages: number = 0;
  public messages: Message[] = [];
  public isNew: boolean = false;
  public isClone: boolean = false;
  public isLoading: boolean = false;
  public isLoadingReadings: boolean = false;
  public isLoadingMessages: boolean = false;
  public shouldRemove: boolean = false;

  public mounted() {
    this.isNew = this.$route.params.simulationId === 'new';
    this.isClone = this.$route.name === 'simulation-clone';
    this.fetchStations();
  }

  public loadRuns() {
    return SimulationService.queryRun(this.simulation.id!).then((simulationRunConfigurations) => {
      this.runConfigurations = simulationRunConfigurations;
    });
  }

  public onConfirmRemove() {
    if (_.isUndefined(this.simulation.id)) {
      return;
    }

    this.isLoading = true;
    SimulationService.remove(this.simulation.id).then(
      () => {
        this.isLoading = false;
        this.addAlert({
          type: 'success',
          header: 'Simulation was removed',
          message: 'You are getting redirected to simulation list page',
          timeout: 5000,
        });
        this.$router.push({ name: 'simulation-list' });
      },
      (error: ErrorEvent) => {
        this.isLoading = false;
        this.addAlert({
          type: 'error',
          header: 'There was a problem removing the Simulation',
          message: error.message,
          timeout: 5000,
        });
      },
    );
  }

  public save() {
    const startDate = moment(`${this.simulation.date} ${this.simulation.time}`);
    const saveSimulation: Partial<SimulationServer> = _({})
      .extend(this.simulation, {
        fromTime: startDate.clone().valueOf(),
        toTime: startDate.clone().add(this.simulation.displayInterval, 'seconds').valueOf(),
        type: this.simulation.stationIds.length > 1 ? 'REGIONAL' : 'SINGLE_STATION',
      })
      .omit(['displayInterval', 'date', 'time'])
      .value();

    SimulationService.save(saveSimulation).then((newSimulation: Simulation) => {
      this.addAlert({
        type: 'success',
        header: this.isClone ? 'Simulation Cloned Successfully' : 'Simulation Created Successfully',
        message: 'You can now configure a new run configuration',
        timeout: 5000,
      });
      this.$router.push({ name: 'simulation-run-configuration', params: { simulationId: `${newSimulation.id}`, runId: 'new' } });
    });
  }

  public getMessagesServerFilters(filters: FiltersSimulation): Partial<MessagesQueryParams> {
    const params = this.getServerFilters(filters);
    return {
      count: -1,
      start: 0,
      includeRegional: true,
      messageTypes: [MESSAGE_TYPES.P_DETECTED, MESSAGE_TYPES.S_DETECTED, MESSAGE_TYPES.SOURCE, MESSAGE_TYPES.EVENT_ENDED],
      sort: { column: 'TIME', ascending: true },
      stationIds: filters.stationIds,
      time: {
        from: params.from,
        to: params.to,
      },
    };
  }

  public getServerFilters(filters: FiltersSimulation): ReadingsRequest {
    const formattedDate = moment(filters.date).format(DATE_DEFAULT_FORMAT);
    const from = filters.time ? moment(`${formattedDate} ${filters.time}`).valueOf() : null;
    const to = filters.time ? moment(`${formattedDate} ${filters.time}`).add(filters.displayInterval, 'seconds').valueOf() : null;
    const stations = this.stations ? this.stations.filter((station) => _.includes(this.simulation.stationIds, station.stationId)) : [];

    const channels = _(stations)
      .map((station) => station.meta.mainChannelId)
      .uniq()
      .value();
    const nodes = _(stations)
      .map((station) => station.meta.mainNodeId)
      .uniq()
      .value();

    return {
      count: 10000,
      channels: channels.length ? channels : null,
      from,
      nodes: nodes.length ? nodes : null,
      stationIds: filters.stationIds,
      timeGrouping: {
        unit: 'MILLIS',
        value: (25 * filters.displayInterval) / 60,
      },
      to,
    };
  }

  public onRunRemoved() {
    this.loadRuns();
  }

  public loadReadings() {
    if (!this.filters) {
      return;
    }

    this.saveScrollPosition();

    this.readings = [];
    this.isLoadingReadings = true;
    ReadingService.query(this.getServerFilters(this.simulation)).then((readings) => {
      this.isLoadingReadings = false;
      this.readings = this.generateChannels(readings);
      this.restoreScrollPosition();
    });
  }

  public loadMessages() {
    if (!this.filters) {
      return;
    }
    this.messages = [];
    this.isLoadingMessages = true;
    MessagesService.query(this.getMessagesServerFilters(this.simulation)).then((response) => {
      this.isLoadingMessages = false;
      this.messages = response.elements;
      this.totalMessages = response.totalCount;
    });
  }

  public getFilteredStations() {
    return this.stations ? this.stations.filter((station) => _.includes(this.simulation?.stationIds, station.stationId)) : [];
  }

  @Watch('stations')
  public onStationsChanged() {
    if (!this.isNew && this.stations?.length) {
      this.isLoading = true;
      SimulationService.find(this.$route.params.simulationId).then((simulation) => {
        this.isLoading = false;
        this.simulation = this.isClone
          ? _.extend({}, _.omit(simulation, ['id']), {
              name: `${simulation.name} clone`,
            })
          : simulation;
        if (!this.isClone) {
          this.loadRuns();
        }
        this.loadReadings();
        this.loadMessages();
      });
    }
  }

  @Watch('filters')
  public onFiltersChange() {
    this.simulation = _.extend({}, this.simulation, this.filters, {
      name: this.simulation.name
        ? this.simulation.name
        : `Simulation ${moment(this.filters?.date).format(DATE_DEFAULT_FORMAT)} ${this.filters?.time} / ${this.filters?.displayInterval}s`,
    });
    if (this.stations?.length) {
      this.loadMessages();
      this.loadReadings();
    }
  }

  private generateChannels(readingsResponse: ReadingsResponse): ChartConfiguration[] {
    return _(readingsResponse.stations)
      .map((station, stationId: string) => {
        const mainChannelId = _.get(_(this.stations).find({ stationId }), 'meta.mainChannelId', 3);
        const mainNodeId = _.get(_(this.stations).find({ stationId }), 'meta.mainNodeId', 0);
        const readings = _.get(station, `nodes[${mainNodeId}].channels[${mainChannelId}].sensorReadings`, []);

        return {
          name: stationId,
          key: stationId,
          series: [
            {
              name: `Node ${mainNodeId}, Channel ${mainChannelId}`,
              key: stationId,
              data: readings.map((reading: any) => [reading.time, reading.value]),
              units: '',
            },
          ],
        };
      })
      .value();
  }
}
