import { LatLongObject } from '@/models/app.model';
import { AlertLevelType } from '@/constants/alert-level.constants';

export interface SeismicPosition {
  lat: number;
  long: number;
  alt?: number;
}

export interface RelativePosition {
  x: number;
  y: number;
  z: number;
}

export interface IntensityPolygon {
  intensity: number;
  polygon: number[][];
  closed: boolean;
}

export class PointOfInterest {
  public customerId?: string;
  public id?: number;
  public location: LatLongObject = { lat: 0, long: 0 };
  public name: string = '';
}

export interface PointOfInterestComplete extends PointOfInterest {
  expectedPga: number;
  pLeadTime: number;
  sLeadTime: number;
  distanceToHypocenterKm: number;
  icon?: any;
  color?: string;
  triggeredRule: {
    alertRuleId: number;
    triggeredAt: number;
    type: string;
    pgaThresholdType: AlertLevelType;
  } | null;
}
