
































































import _ from 'lodash';
import { Action, Getter } from 'vuex-class';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { NS_FILTERS_SIMULATION_ANALYSIS, NS_STATIONS } from '@/constants/app.constants';
import { FiltersSimulationAnalysis } from '@/models/states/filters-state.model';
import { Simulation, SimulationRunConfiguration } from '@/models/simulation.model';
import { Station } from '@/models/station.model';
import { Node } from '@/models/node.model';
import { Options } from '@/models/app.model';
import { SimulationService } from '@/services/simulation.service';
import { Permissions } from '@/services/permissions.service';
import { QueryService } from '@/services/query.service';
import BPFilterSelect from '@/components/shared/BPFilterSelect.component.vue';
import ChannelsTreeSelect from '@/components/shared/ChannelsTreeSelect.component.vue';
import MessageTypeSelect from '@/components/shared/MessageTypeSelect.component.vue';
import NodeSelect from '@/components/shared/NodesSelect.component.vue';
import RunSelect from '@/components/shared/RunSelect.component.vue';
import StationsSelectTree from '@/components/shared/StationsSelectTree.component.vue';

@Component({
  name: 'OptionsSimulationAnalysis',
  components: {
    BPFilterSelect,
    ChannelsTreeSelect,
    MessageTypeSelect,
    NodeSelect,
    RunSelect,
    StationsSelectTree,
  },
})
export default class OptionsSimulationAnalysis extends Vue {
  @Prop() public simulation!: Simulation;
  @Getter('getAllStations', { namespace: NS_STATIONS }) stations?: Station[];
  @Action('fetchStations', { namespace: NS_STATIONS }) public fetchStations: any;
  @Action('updateFilters', { namespace: NS_FILTERS_SIMULATION_ANALYSIS }) public updateFilters: any;
  @Getter('getFilters', { namespace: NS_FILTERS_SIMULATION_ANALYSIS }) public filters?: FiltersSimulationAnalysis;

  public filteredStations: Station[] = [];
  public runConfigurations: SimulationRunConfiguration[] = [];
  public isOpen: number = 0;
  public isAdmin: boolean = false;
  public innerFilters: Partial<FiltersSimulationAnalysis> = {};
  public nodes: Options<number>[] = [];

  public mounted() {
    this.isAdmin = Permissions.isAdmin();

    this.onStationChange();
    this.loadRuns().then(() => {
      this.applyUrlParams();
    });
  }

  public loadRuns(): Promise<SimulationRunConfiguration[]> {
    return SimulationService.queryRun(this.simulation.id!).then((simulationRunConfigurations) => {
      this.runConfigurations = simulationRunConfigurations;
      return simulationRunConfigurations;
    });
  }

  @Watch('filters')
  public onFiltersChange() {
    if (this.filters) {
      this.innerFilters = _.cloneDeep(this.filters);
      this.updateQueryParams();
    }
  }

  @Watch('$route')
  public onRouteChange() {
    this.applyUrlParams();
  }

  public applyFilters(filters: Partial<FiltersSimulationAnalysis> | undefined = this.innerFilters) {
    this.updateFilters(filters);
    this.$emit('onRunChange', _(this.runConfigurations).find({ id: filters.run }));
  }

  public downloadResults() {
    if (this.simulation.id && this.innerFilters.run) {
      SimulationService.downloadResults(this.simulation.id, this.innerFilters.run);
    }
  }

  public resetZoom() {
    this.applyFilters({
      zoomEnd: 0,
      zoomStart: 0,
    });
  }

  public updateQueryParams() {
    const newQueryParams = _.omit(this.innerFilters, ['date', 'time']);
    const queryParams = _.omit(this.getQueryParams(), ['date', 'time']);

    if (_.isEqual(newQueryParams, queryParams)) {
      return;
    }

    this.$router
      .push(
        _.extend({}, this.$route, {
          query: QueryService.convertQueryParamsToString(newQueryParams),
        }),
      )
      .catch(() => {});
  }

  @Watch('stations')
  public onStationChange() {
    if (this.stations) {
      this.filteredStations = this.stations.filter((station: Station) => _.includes(this.simulation.stationIds, station.stationId));

      this.nodes = _(this.filteredStations)
        .map((station: Station) => station.nodes)
        .flatten()
        .uniqBy('nodeId')
        .map((node: Node) => ({
          name: `Node ${node.nodeId}`,
          value: node.nodeId,
        }))
        .value();
    } else {
      this.filteredStations = [];
    }
  }

  private applyUrlParams() {
    if (!_.isEmpty(this.$route.query)) {
      this.applyFilters(this.getQueryParams());
    } else {
      this.setDefaults();
      this.applyFilters();
    }
  }

  private setDefaults() {
    this.innerFilters = _.extend({}, this.innerFilters, {
      run: this.getDefaultRunId(),
      observedTime: this.simulation.toTime,
    });
  }

  private getDefaultRunId(): number {
    return _.first(this.runConfigurations) ? _.first(this.runConfigurations)?.id! : -1;
  }

  private getQueryParams(): Partial<FiltersSimulationAnalysis> {
    if (!_.isEmpty(this.$route.query)) {
      const run = _.isUndefined(this.$route.query.run) ? -1 : parseInt(`${this.$route.query.run}`, 10);
      const zoomStart = _.isUndefined(this.$route.query.zoomStart) ? -1 : parseInt(`${this.$route.query.zoomStart}`, 10);
      const zoomEnd = _.isUndefined(this.$route.query.zoomEnd) ? -1 : parseInt(`${this.$route.query.zoomEnd}`, 10);
      return {
        bpFilter: QueryService.getBPFilter(this.$route),
        channels: QueryService.getSimpleChannels(this.$route),
        messageTypes: QueryService.getMessageTypes(this.$route),
        nodes: QueryService.getNodes(this.$route),
        run: run === -1 ? this.getDefaultRunId() : run,
        stationIds: QueryService.getStations(this.$route) || [],
        zoomEnd,
        zoomStart,
      };
    }
    return {};
  }
}
