










































































































import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { DataTableHeader } from 'vuetify';
import { NS_ALERTS } from '@/constants/app.constants';
import { Message } from '@/models/message.model';
import { Simulation } from '@/models/simulation.model';
import { SimulationService } from '@/services/simulation.service';
import ConfirmationDialog from '@/components/shared/ConfirmationDialog.component.vue';

@Component({
  name: 'SimulationsListView',
  components: {
    ConfirmationDialog,
  },
})
export default class SimulationsListView extends Vue {
  @Action('addAlert', { namespace: NS_ALERTS }) public addAlert: any;

  public tableHeaders: DataTableHeader[] = [
    { text: 'Id', value: 'id', width: 80 },
    { text: 'Date', value: 'createdAt', width: 180 },
    { text: 'Name', value: 'name' },
    { text: 'Status', value: 'status' },
    { text: 'Status Description', value: 'statusDescription' },
    { text: 'Description', value: 'description' },
    { text: 'Stations', value: 'stationIds' },
    { text: 'Event', value: 'eventId', width: 120 },
    { text: '#Runs', value: 'runCount', width: 120 },
    { text: 'Start', value: 'fromTime', width: 180 },
    { text: 'End', value: 'toTime', width: 180 },
    { text: 'Created By', value: 'createdBy', width: 120 },
    { text: '', value: 'actions', align: 'end', width: 220 },
  ];
  public isLoading: boolean = false;
  public simulations: Simulation[] = [];
  public shouldRemove: boolean = false;
  public selectedSimulationId: number | null = null;

  public mounted() {
    this.loadList();
  }

  public remove(row: Message) {
    this.shouldRemove = true;
    this.selectedSimulationId = row.id;
  }

  public onConfirmRemove() {
    if (!this.selectedSimulationId) {
      return;
    }

    this.isLoading = true;
    SimulationService.remove(this.selectedSimulationId).then(
      () => {
        this.isLoading = false;
        this.addAlert({
          type: 'success',
          header: 'Simulation was removed',
          message: 'The list will refresh',
          timeout: 5000,
        });
        this.loadList();
      },
      (error: ErrorEvent) => {
        this.isLoading = false;
        this.addAlert({
          type: 'error',
          header: 'There was a problem removing the Simulation',
          message: error.message,
          timeout: 5000,
        });
      },
    );
  }

  private loadList() {
    this.isLoading = true;
    SimulationService.query({
      start: 0,
      count: 0,
      sort: {
        column: 'NAME',
        ascending: true,
      },
    }).then(
      (response) => {
        this.isLoading = false;
        this.simulations = response.elements;
      },
      () => {
        this.isLoading = false;
        this.addAlert({
          type: 'error',
          header: 'Something went wrong',
          message: 'There a was a problem loading the Simulations',
          timeout: 5000,
        });
      },
    );
  }
}
