var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-footer',{staticClass:"mb-3 py-2 px-2",staticStyle:{"border-radius":"4px"}},[_c('v-row',{attrs:{"align-content":"center","align":"center"}},[_c('v-col',{staticClass:"col--justify-end"},[_c('v-btn',{attrs:{"small":"","color":"secondary","link":"","to":{ name: 'simulation-details', params: { simulationId: 'new' } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" Add Simulation ")],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-10 simulation-list-table",attrs:{"loading":_vm.isLoading,"headers":_vm.tableHeaders,"items":_vm.simulations,"items-per-page":-1,"fixed-header":""},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("getDateTime")(item.createdAt)))])]}},{key:"item.fromTime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("getDateTime")(item.fromTime)))])]}},{key:"item.toTime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("getDateTime")(item.toTime)))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","link":"","to":item.runCount && item.runCount > 0
                ? { name: 'simulation-analysis', params: { simulationId: item.id } }
                : { name: 'simulation-details', params: { simulationId: item.id } },"color":"primary"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('section',{staticClass:"table__actions"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"secondary","small":"","icon":"","link":"","to":{ name: 'simulation-clone', params: { simulationId: item.id } }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-strategy")])],1)]}}],null,true)},[_c('span',[_vm._v("Clone")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","small":"","icon":"","link":"","to":{ name: 'simulation-details', params: { simulationId: item.id } }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"error","small":"","icon":""},on:{"click":function($event){return _vm.remove(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)]}}])})],1)],1),_c('ConfirmationDialog',{attrs:{"open":_vm.shouldRemove,"header":"Confirm Simulation Removal","dismiss-label":"Cancel","confirm-label":"Remove","message":"Are you sure you want to remove this Simulation?"},on:{"update:open":function($event){_vm.shouldRemove=$event},"onConfirm":_vm.onConfirmRemove}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }