import { render, staticRenderFns } from "./SimpleMap.component.vue?vue&type=template&id=79b3ba3a&scoped=true"
import script from "./SimpleMap.component.vue?vue&type=script&lang=ts"
export * from "./SimpleMap.component.vue?vue&type=script&lang=ts"
import style0 from "./SimpleMap.component.vue?vue&type=style&index=0&id=79b3ba3a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79b3ba3a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCardTitle,VImg})
