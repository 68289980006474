
















import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Rule } from '@/models/rule.model';
import { RulesService } from '@/services/rules.service';

@Component({
  name: 'RuleSelect',
})
export default class RuleSelect extends Vue {
  @Prop() public model!: string[];
  @Prop() public disabled?: boolean;
  @Prop() public customerId?: string;

  public rules: Rule[] = [];
  public innerModel: string[] = [];

  public mounted() {
    this.loadRules();
    this.onModelChange();
  }

  @Watch('customerId')
  public onCustomerChange() {
    this.innerModel = [];
    this.$emit('update:model', this.innerModel);
    this.loadRules();
  }

  @Watch('model')
  public onModelChange() {
    this.innerModel = _.clone(this.model);
  }

  public loadRules() {
    RulesService.query({ customerId: this.customerId, start: 0, count: 100 }).then((response) => {
      this.rules = response.alertRules;
    });
  }
}
