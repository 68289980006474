var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-spacer'),_c('v-col',{staticClass:"pr-6",attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"small":"","block":"","color":"secondary","link":"","to":{ name: 'simulation-run-configuration', params: { simulationId: _vm.simulation.id, runId: 'new' } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" Add new run ")],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 mb-5",attrs:{"headers":_vm.headers,"items":_vm.runs,"loading":_vm.isLoading,"options":_vm.tableOptions,"server-items-length":_vm.size,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":_vm._f("getColorFromRunStatus")(item.status)}},[_vm._v(" "+_vm._s(_vm._f("getIconFromRunStatus")(item.status))+" ")]),_c('span',[_vm._v(_vm._s(item.status))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","small":"","icon":"","link":"","to":{ name: 'simulation-run-configuration', params: { simulationId: _vm.simulation.id, runId: item.id } }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"secondary","small":"","icon":"","link":"","to":{ name: 'simulation-run-configuration-clone', params: { simulationId: _vm.simulation.id, runId: item.id } }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-strategy")])],1)]}}],null,true)},[_c('span',[_vm._v("Clone")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"error","small":"","icon":""},on:{"click":function($event){return _vm.remove(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}])}),_c('ConfirmationDialog',{attrs:{"open":_vm.shouldRemove,"header":"Confirm Run Removal","dismiss-label":"Cancel","confirm-label":"Remove","message":"Are you sure you want to remove this Run?"},on:{"update:open":function($event){_vm.shouldRemove=$event},"onConfirm":_vm.onConfirmRemove}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }