
























import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import StationConfigService from '@/services/station-config.service';
import { ServiceVersion } from '@/models/station.model';

@Component({
  name: 'IntraVersionSelect',
})
export default class IntraVersionSelect extends Vue {
  @Prop() public model!: string;
  @Prop() public disabled!: boolean;

  public versions: ServiceVersion[] = [];
  public innerModel: string = '';
  public search: string = '';
  public isLoading: boolean = true;
  public onSearch: () => void = () => {};

  public mounted() {
    this.onModelChange();
    this.onSearch = _.debounce(this.loadVersions, 1000);
    this.onSearch();
  }

  public loadVersions() {
    this.isLoading = true;
    StationConfigService.queryServiceVersions({
      stationIds: null,
      statuses: null,
      services: ['R2D2'],
      textSearch: this.search,
      count: 100,
      start: 0,
      itemsPerPage: 100,
      page: 0,
    }).then(
      (response) => {
        this.isLoading = false;
        this.versions = response.elements;
      },
      () => {
        this.isLoading = false;
      },
    );
  }

  @Watch('model')
  public onModelChange() {
    this.innerModel = _.clone(this.model);
  }

  @Watch('search')
  public onChange() {
    this.onSearch();
  }
}
