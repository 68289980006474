
























































































import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { DataTableHeader } from 'vuetify';
import { NS_ALERTS } from '@/constants/app.constants';
import { Message } from '@/models/message.model';
import { Simulation, SimulationRunConfiguration } from '@/models/simulation.model';
import { SimulationService } from '@/services/simulation.service';
import ConfirmationDialog from '@/components/shared/ConfirmationDialog.component.vue';

@Component({
  name: 'RunsTable',
  components: {
    ConfirmationDialog,
  },
})
export default class RunsTable extends Vue {
  @Prop() public simulation!: Simulation;
  @Prop() public runs!: SimulationRunConfiguration[];
  @Prop() public isLoading!: boolean;
  @Prop() public size!: number;
  @Action('addAlert', { namespace: NS_ALERTS }) public addAlert: any;

  public shouldRemove: boolean = false;
  public selectedRunId: number | null = null;
  public openDialog: boolean = false;
  public dialogRow: Message | null = null;
  public headers: DataTableHeader[] = [
    { text: 'Id', value: 'id', sortable: false },
    { text: 'Description', value: 'description', sortable: false, width: 200 },
    { text: 'Status', value: 'status', sortable: false, width: 150 },
    { text: 'Status Description', value: 'statusDescription' },
    { text: '', value: 'actions', align: 'end' },
  ];
  public tableOptions: any = {
    page: 1,
    itemsPerPage: 10,
    sortBy: ['id'],
    sortDesc: [true],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  };

  public remove(row: Message) {
    this.shouldRemove = true;
    this.selectedRunId = row.id;
  }

  public onConfirmRemove() {
    if (!this.selectedRunId || _.isUndefined(this.simulation.id)) {
      return;
    }

    this.isLoading = true;
    SimulationService.removeRun(this.simulation.id, this.selectedRunId).then(
      () => {
        this.isLoading = false;
        this.addAlert({
          type: 'success',
          header: 'Run was removed',
          message: 'The list will refresh',
          timeout: 5000,
        });
        this.$emit('run:removed');
      },
      (error: ErrorEvent) => {
        this.isLoading = false;
        this.addAlert({
          type: 'error',
          header: 'There was a problem removing the Run',
          message: error.message,
          timeout: 5000,
        });
      },
    );
  }
}
