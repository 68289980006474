import { PointOfInterest, PointOfInterestComplete, SeismicPosition } from '@/models/position.model';
import { MessageSource } from '@/models/message.model';

export interface SimulationQueryPrams {
  start: number;
  count: number;
  sort: {
    column: string;
    ascending: boolean;
  };
}

export interface SimulationServer {
  id?: number;
  name: string;
  description: string;
  stationIds: string[];
  eventId?: string;
  fromTime: number;
  toTime: number;
  createdAt?: number;
  createdBy?: string;
  status?: string;
  statusDescription?: string;
  runCount?: number;
  type?: string;
}

export interface Simulation extends SimulationServer {
  date: string;
  time: string;
  displayInterval: number;
}

export interface SimulationConfiguration {
  date: string;
  displayInterval: number;
  stationIds: string[];
  time: string;
  name: string;
  description: string;
}

export interface SimulationQueryResponse {
  start: number;
  count: number;
  totalCount: number;
  elements: Simulation[];
}

export interface SimulationRunStationConfiguration {
  stationId: string;
  r2d2Config: string | null;
  ackbarConfig: string | null;
}

export class SimulationRunConfiguration {
  public id?: number | null = null;
  public createdAt: number = 0;
  public stationConfigs: SimulationRunStationConfiguration[] = [];
  public regionalConfig: string = '';
  public name: string = '';
  public description: string = '';
  public catalogEpicenterLocation: SeismicPosition = {
    lat: 0,
    long: 0,
  };
  public status?: string;
  public statusDescription?: string;
  public pointsOfInterest: PointOfInterest[] = [];
  public serviceVersions?: {
    intra: null | string;
    eqa: null | string;
  } = { intra: null, eqa: null };
}

export interface SimulationRegional {
  lastSourceMessage: MessageSource;
  magLine: [number, number][];
  momentLine: [number, number][];
  stressDropLine: [number, number][];
  pointsOfInterest: PointOfInterestComplete[];
}

export interface SimulationRegionalRequest {
  from: number;
  to: number;
  observedTime: number;
}
