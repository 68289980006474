import { Options } from '@/models/app.model';
import { StationHealthStatus } from '@/constants/station.constants';

export const STATION_CONFIGURATION_STATUSES: Options<string>[] = [
  { value: 'ACTIVE', name: 'Active', icon: 'mdi-check-outline', color: 'light-green lighten-1' },
  { value: 'PENDING', name: 'Pending', icon: 'mdi-alert', color: 'orange lighten-2' },
  { value: 'FAILED', name: 'Failed', icon: 'mdi-comment-remove', color: 'red lighten-1' },
  { value: 'UNUSED', name: 'Unused', icon: '', color: '' },
  { value: 'NEVER_USED', name: 'Never Used', icon: '', color: '' },
];

export const STATION_CONFIGURATION_SERVICES: Options<string>[] = [
  { value: 'R2D2', name: 'R2D2' },
  { value: 'ACKBAR', name: 'ACKBAR' },
  { value: 'JABBA', name: 'JABBA' },
  { value: 'RABBITMQ', name: 'RABBITMQ' },
  { value: 'RADDUS', name: 'RADDUS' },
  { value: 'SEEDLINK_CLIENT', name: 'SEEDLINK_CLIENT' },
  { value: 'UNIFIED', name: 'UNIFIED' },
];

export const STATION_CONFIGURATION_TYPES: Options<string>[] = [
  { value: 'SERVICE_CONFIG_FILE', name: 'Config File Update' },
  { value: 'SERVICE_VERSION', name: 'Version Update' },
];

export const STATION_STATUSES: Options<string>[] = [
  { value: StationHealthStatus.HEALTHY, name: 'Healthy' },
  { value: StationHealthStatus.UNHEALTHY, name: 'Unhealthy' },
  { value: StationHealthStatus.WARNING, name: 'Warning' },
];
