




























import _ from 'lodash';
import { Action, Getter } from 'vuex-class';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { NS_FILTERS_SIMULATION, NS_STATIONS } from '@/constants/app.constants';
import { FiltersSimulation } from '@/models/states/filters-state.model';
import { Simulation } from '@/models/simulation.model';
import { Station } from '@/models/station.model';
import DateTimeSelector from '@/components/shared/DateTimeSelector.component.vue';
import StationsSelectTree from '@/components/shared/StationsSelectTree.component.vue';

@Component({
  name: 'OptionsSimulation',
  components: {
    DateTimeSelector,
    StationsSelectTree,
  },
})
export default class OptionsSimulation extends Vue {
  @Prop() public simulation!: Simulation;
  @Prop() public disabled?: boolean;
  @Getter('getAllStations', { namespace: NS_STATIONS }) stations?: Station[];
  @Action('fetchStations', { namespace: NS_STATIONS }) public fetchStations: any;
  @Action('updateFilters', { namespace: NS_FILTERS_SIMULATION }) public updateFilters: any;
  @Getter('getFilters', { namespace: NS_FILTERS_SIMULATION }) public filters?: FiltersSimulation;

  public isOpen: number = 0;
  public innerFilters: Partial<FiltersSimulation> = {};

  public mounted() {
    this.onFiltersChange();
    this.onSimulationChange();
  }

  @Watch('filters')
  public onFiltersChange() {
    if (this.filters) {
      this.innerFilters = _.cloneDeep(this.filters);
    }
  }

  @Watch('simulation')
  public onSimulationChange() {
    this.innerFilters = _.extend({}, this.innerFilters, {
      stationIds: this.simulation.stationIds,
      displayInterval: this.simulation.displayInterval,
      date: this.simulation.date,
      time: this.simulation.time,
    });
  }

  public onNameChange() {
    this.applyFilters();
  }

  public applyFilters(filters: Partial<FiltersSimulation> | undefined = this.innerFilters) {
    this.updateFilters(filters);
  }
}
