
















import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { PointOfInterest } from '@/models/position.model';

@Component({
  name: 'POISelect',
})
export default class POISelect extends Vue {
  @Prop() public model!: string[];
  @Prop() public disabled?: boolean;
  @Prop() public pointsOfInterest!: PointOfInterest[];

  public innerModel: string[] = [];

  public mounted() {
    this.onModelChange();
  }

  @Watch('model')
  public onModelChange() {
    this.innerModel = _.clone(this.model);
  }
}
