




































































import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { NS_POI } from '@/constants/app.constants';
import { PointOfInterest } from '@/models/position.model';
import { Rule } from '@/models/rule.model';
import SimpleMap from '@/components/shared/SimpleMap.component.vue';
import LoadingBar from '@/components/shared/LoadingBar.component.vue';
import RuleSelect from '@/components/shared/RuleSelect.component.vue';
import { Permissions } from '@/services/permissions.service';
import CustomerSelect from '@/components/shared/CustomerSelect.component.vue';
import { RulesService } from '@/services/rules.service';

@Component({
  name: 'PointOfInterestAddDialog',
  components: {
    CustomerSelect,
    LoadingBar,
    RuleSelect,
    SimpleMap,
  },
})
export default class PointOfInterestAddDialog extends Vue {
  @Prop() public pointOfInterest?: PointOfInterest;
  @Prop() public isNew?: boolean;
  @Prop({ default: false }) public open!: boolean;
  @Getter('getPointsOfInterestLoading', { namespace: NS_POI }) isLoading?: boolean;
  @Getter('getPointsOfInterestSaveSuccessful', { namespace: NS_POI }) isSaved?: boolean;
  @Action('savePointOfInterest', { namespace: NS_POI }) public savePointOfInterest: any;

  public rules?: Rule[] = [];
  public innerPointOfInterest: PointOfInterest = new PointOfInterest();
  public isOpen: boolean = false;
  public formValid: boolean = false;
  public isEdit: boolean = false;
  public isAdmin: boolean = false;
  public selectedRules: Rule[] = [];

  public mounted() {
    this.isAdmin = Permissions.isAdmin();
    this.onModelChange();
    this.onPOIChange();
  }

  @Watch('open')
  public onModelChange() {
    this.isOpen = this.open;
    if (this.isOpen && this.innerPointOfInterest.id && !this.isNew) {
      RulesService.getPOIRules(this.innerPointOfInterest.id).then((rules: Rule[]) => {
        this.selectedRules = rules;
      });
    }
  }

  @Watch('pointOfInterest')
  public onPOIChange() {
    this.isEdit = !!this.pointOfInterest?.id;
    if (this.pointOfInterest) {
      this.innerPointOfInterest = _.cloneDeep(this.pointOfInterest);
    }
  }

  @Watch('isSaved')
  public onSaved() {
    if (this.isSaved) {
      this.cancel();
    }
  }

  public onChange() {
    this.$emit('update:open', this.isOpen);
  }

  public cancel() {
    this.isOpen = false;
    this.onChange();
  }

  public save() {
    this.savePointOfInterest(this.innerPointOfInterest);
    if (this.isAdmin) {
      RulesService.savePOIRules(
        this.innerPointOfInterest.id!,
        this.selectedRules.map((rule) => rule.id!),
      ).then(() => {});
    }
  }

  public showMap() {
    return this.innerPointOfInterest.name && this.innerPointOfInterest.location.lat !== 0 && this.innerPointOfInterest.location.long !== 0;
  }
}
