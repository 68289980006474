








































import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { SimulationRunConfiguration } from '@/models/simulation.model';

@Component({
  name: 'RunSelect',
})
export default class RunSelect extends Vue {
  @Prop() public model!: string[];
  @Prop() public runs!: SimulationRunConfiguration[];

  public innerModel: string[] = [];

  public mounted() {
    this.onModelChange();
  }

  @Watch('model')
  public onModelChange() {
    this.innerModel = _.clone(this.model);
  }
}
