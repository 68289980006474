import axios from 'axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import { DATE_SECONDARY_FULL_FORMAT } from '@/constants/app.constants';
import { STATION_CONFIGURATION_STATUSES } from '@/constants/station-configuration.constants';
import {
  ServiceVersion,
  ServiceVersionApply,
  StationConfiguration,
  StationConfigurationBasic,
  StationConfigurationFile,
  StationConfigurationFileApply,
} from '@/models/station.model';
import { Options } from '@/models/app.model';
import { FiltersStationConfiguration, FiltersStationConfigurationHistory } from '@/models/states/filters-state.model';

export default class StationConfigService {
  public static query(params: Partial<FiltersStationConfigurationHistory> = {}): Promise<{ totalCount: number; elements: StationConfiguration[] }> {
    return axios
      .post<{ totalCount: number; elements: StationConfiguration[] }>(`/stations/configs/applied/query`, params)
      .then((response) => response.data)
      .then((response) => ({
        totalCount: response.totalCount,
        elements: response.elements.map((stationConfiguration: StationConfiguration) => this.parseStationConfiguration(stationConfiguration)),
      }));
  }

  public static queryServiceVersions(params: FiltersStationConfiguration): Promise<{ totalCount: number; elements: ServiceVersion[] }> {
    return axios
      .post<{ totalCount: number; elements: ServiceVersion[] }>(`/stations/configs/service-versions/query`, params)
      .then((response) => response.data)
      .then((response) => ({
        totalCount: response.totalCount,
        elements: response.elements.map((serviceVersion: ServiceVersion) => this.parseServiceVersion(serviceVersion)),
      }));
  }

  public static applyServiceVersions(params: ServiceVersionApply): Promise<unknown> {
    return axios
      .post<unknown>(`/stations/configs/service-versions/apply`, {
        stationServiceVersionId: params.stationServiceVersionId,
        description: params.description,
        stationIds: params.stationIds,
      })
      .then((response) => response.data);
  }

  public static restartServices(stationId: string): Promise<unknown> {
    return axios.post<unknown>(`/stations/${stationId}/commands/restart-containers`, {}).then((response) => response.data);
  }

  public static restartSSH(stationId: string): Promise<unknown> {
    return axios.post<unknown>(`/stations/${stationId}/commands/restart-ssh`, {}).then((response) => response.data);
  }

  public static reboot(stationId: string): Promise<unknown> {
    return axios.post<unknown>(`/stations/${stationId}/commands/restart-machine`, {}).then((response) => response.data);
  }

  public static removeServiceVersion(params: ServiceVersion): Promise<unknown> {
    return axios.delete<unknown>(`/stations/configs/service-version/${params.id}`).then((response) => response.data);
  }

  public static queryStationConfigurationFiles(
    params: FiltersStationConfiguration,
  ): Promise<{ totalCount: number; elements: StationConfigurationFile[] }> {
    return axios
      .post<{ totalCount: number; elements: StationConfigurationFile[] }>(`/stations/configs/config-files/query`, params)
      .then((response) => response.data)
      .then((response) => ({
        totalCount: response.totalCount,
        elements: response.elements.map((configurationFile: StationConfigurationFile) => this.parseConfigurationFile(configurationFile)),
      }));
  }

  public static applyStationConfigurationFile(params: StationConfigurationFileApply): Promise<{ totalCount: number; elements: ServiceVersion[] }> {
    return axios
      .post<{ totalCount: number; elements: ServiceVersion[] }>(`/stations/configs/config-files/apply`, {
        stationConfigFileId: params.stationConfigFileId,
        description: params.description,
      })
      .then((response) => response.data);
  }

  public static removeStationConfigurationFile(
    params: StationConfigurationFile,
  ): Promise<{ totalCount: number; elements: StationConfigurationFile[] }> {
    return axios
      .delete<{ totalCount: number; elements: StationConfigurationFile[] }>(`/stations/configs/config-files/${params.id}`)
      .then((response) => response.data);
  }

  private static parseServiceVersion(serviceVersion: ServiceVersion) {
    return _.extend({}, serviceVersion, {
      parsed: {
        createdAt: moment(serviceVersion.createdAt).format(DATE_SECONDARY_FULL_FORMAT),
      },
      appliedOn: serviceVersion.appliedOn
        ? serviceVersion.appliedOn.map((version) => {
            const parsedStatus = this.getParsedStatus(version);
            return _.extend({}, version, {
              parsed: {
                status: parsedStatus.name,
                icon: parsedStatus.icon,
                color: parsedStatus.color,
              },
            });
          })
        : [],
    });
  }

  private static parseConfigurationFile(configurationFile: StationConfigurationFile) {
    return _.extend({}, configurationFile, {
      parsed: {
        createdAt: moment(configurationFile.createdAt).format(DATE_SECONDARY_FULL_FORMAT),
        hashShort: configurationFile.hash.substr(0, 25),
        canApply: this.computeCanApplyConfigurationFile(configurationFile),
      },
      appliedOn: configurationFile.appliedOn
        ? configurationFile.appliedOn?.map((version) => {
            const parsedStatus = this.getParsedStatus(version);
            return _.extend({}, version, {
              parsed: {
                status: parsedStatus.name,
                icon: parsedStatus.icon,
                color: parsedStatus.color,
              },
            });
          })
        : [],
    });
  }

  private static computeCanApplyConfigurationFile(configurationFile: StationConfigurationFile) {
    const isActive = configurationFile.appliedOn != null && _.find(configurationFile.appliedOn, (appliedConfig) => appliedConfig.status === 'ACTIVE');

    // keep it always on for now, we want to apply the same config multiple times until things are more stable
    return isActive || true;
  }

  private static parseStationConfiguration(stationConfiguration: StationConfiguration) {
    return _.extend({}, stationConfiguration, {
      parsed: {
        appliedAt: moment(stationConfiguration.appliedAt).format(DATE_SECONDARY_FULL_FORMAT),
        description: this.getChangeDescription(stationConfiguration),
        status: this.getParsedStatus(stationConfiguration).name,
        icon: this.getParsedStatus(stationConfiguration).icon,
        color: this.getParsedStatus(stationConfiguration).color,
        type: this.getParsedType(stationConfiguration),
      },
    });
  }

  private static getParsedStatus(stationConfiguration: StationConfigurationBasic | StationConfigurationFile): Options<string> {
    const option = _(STATION_CONFIGURATION_STATUSES).find({ value: stationConfiguration.status });
    if (option) {
      return option;
    }
    return {
      value: stationConfiguration.status,
      name: stationConfiguration.status,
      icon: '',
      color: '',
    };
  }

  private static getParsedType(stationConfiguration: StationConfiguration) {
    if (stationConfiguration.type === 'SERVICE_VERSION') {
      return 'Version Update';
    }
    if (stationConfiguration.type === 'SERVICE_CONFIG_FILE') {
      return 'Config File Update';
    }
    return stationConfiguration.type;
  }

  private static getChangeDescription(stationConfiguration: StationConfiguration) {
    if (stationConfiguration.type === 'SERVICE_VERSION') {
      return `Version upgrade: ${_.get(stationConfiguration, 'oldVersion.version', 'N/A')} -> ${_.get(
        stationConfiguration,
        'newVersion.version',
        'N/A',
      )}`;
    }
    if (stationConfiguration.type === 'SERVICE_CONFIG_FILE' && stationConfiguration.service === 'UNIFIED') {
      return `Configuration upgrade: ${_.get(stationConfiguration, 'oldConfig.version', 'N/A')} -> ${_.get(
        stationConfiguration,
        'newConfig.version',
        'N/A',
      )}`;
    }
     if (stationConfiguration.type === 'SERVICE_CONFIG_FILE')  {
      return `Updated: ${_.get(stationConfiguration, 'newConfigFile.fileName', 'N/A')} to version ${_.get(
        stationConfiguration,
        'newConfigFile.version',
        'N/A',
      )}`;
    }
    return 'No description';
  }
}
