import axios from 'axios';
import { Rule, RuleQueryParams, RuleQueryResponse } from '@/models/rule.model';

export class RulesService {
  public static query(params: Partial<RuleQueryParams>): Promise<RuleQueryResponse> {
    return axios.post<RuleQueryResponse>(`/alert_rules/query`, params).then((response) => response.data);
  }

  public static find(ruleId: number | string): Promise<Rule> {
    return axios.get<Rule>(`/alert_rules/${ruleId}`).then((response) => response.data);
  }

  public static save(rule: Partial<Rule>): Promise<Rule> {
    return axios.post<Rule>(`/alert_rules`, rule).then((response) => response.data);
  }

  public static remove(ruleId: number): Promise<Rule> {
    return axios.delete<Rule>(`/alert_rules/${ruleId}`).then((response) => response.data);
  }

  public static getPOIRules(poiId: number): Promise<Rule[]> {
    return axios.get<Rule[]>(`/alert_rules/poi/${poiId}/associated_rules`).then((response) => response.data);
  }

  public static savePOIRules(poiId: number, ruleIds: number[]): Promise<Rule[]> {
    return axios.post<Rule[]>(`/alert_rules/poi/${poiId}/associated_rules`, ruleIds).then((response) => response.data);
  }
}
