











import { Component, Prop, Vue } from 'vue-property-decorator';
import { iconPoi } from '@/constants/leaflet.constants';
import { Icon } from 'leaflet';

@Component({
  name: 'SimpleMap',
})
export default class SimpleMap extends Vue {
  @Prop({ default: '' }) public name?: string;
  @Prop({ default: 10 }) public zoom?: number;
  @Prop({ default: 10 }) public center!: number[];

  public icon: Icon = iconPoi;
  public url: string = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  public attribution: string = '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a>';
}
